define("discourse/plugins/discourse-uipath/discourse/initializers/uipath-initializer", ["exports", "@ember/object", "discourse/components/modal/create-account", "discourse/lib/plugin-api", "discourse/models/login-method", "I18n"], function (_exports, _object, _createAccount, _pluginApi, _loginMethod, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "uipath-initializer",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.37", api => {
        if (container.lookup("service:site-settings").uipath_custom_signup_enabled) {
          // Add a parameter to the signup flow, so the IDP starts
          // with the sign up screen
          api.modifyClass("route:application", {
            pluginId: "discourse-uipath",
            actions: {
              showCreateAccount() {
                const loginMethod = (0, _loginMethod.findAll)().find(m => m.name === "oidc");
                return loginMethod.doLogin({
                  params: {
                    type: "signup"
                  }
                });
              }
            }
          });
        }
        const descriptors = Object.getOwnPropertyDescriptors(_createAccount.default.prototype);

        // Copy the old `createAccount` implementation to a different
        // property name before we add our new implementation.
        if (!descriptors["_origCreateAccount"]) {
          Object.defineProperty(_createAccount.default.prototype, "_origCreateAccount", descriptors["createAccount"]);
        }

        // Check that the terms have been accepted when creating a new account
        api.modifyClass("component:modal/create-account", dt7948.p({
          pluginId: "discourse-uipath",
          createAccount() {
            if (this.authOptions.requires_terms_acceptance && !this.authOptions.termsAccepted) {
              this.set("flash", _I18n.default.t("uipath.terms_error_message"));
              return;
            }
            this._origCreateAccount(...arguments);
          }
        }, [["method", "createAccount", [_object.action]]]));
      });
    }
  };
});